import { createContext, useContext } from 'react';

import type { WProductView } from '@zola/svc-web-api-ts-client';

import type { RegistryType } from '@/types/registry';

export type PublicRegistryContextValues = {
  registryKey: string;
  registryOverview: RegistryType | undefined;
  isOwnRegistry: boolean;
  giftCardData: WProductView | null;
};

export const PublicRegistryContext = createContext<PublicRegistryContextValues>({
  registryKey: '',
  registryOverview: undefined,
  isOwnRegistry: false,
  giftCardData: null,
});

export const usePublicRegistry = () => useContext(PublicRegistryContext);
