import { useEffect, useMemo } from 'react';

import type { WProductView, WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import queryString from 'query-string';

import { PublicRegistryTile } from './PublicRegistryTile';
import { RegistryTilesContainer, PublicTileContainer } from './PublicRegistryTiles.styles';
import { RegistryZolaGiftCard } from './RegistryZolaGiftCard';
import type { ListItem } from './types';
import { isItemAvailable } from './utils';

interface PublicRegistryTilesProps {
  items?: WRegistryCollectionItemView[];
  zolaGiftCard?: WProductView | null;
  onItemClick: (item: WRegistryCollectionItemView | WProductView, qty?: number) => void;
  openItemDetail: (item: WRegistryCollectionItemView) => void;
  isZolaGiftCardEnabled?: boolean;
}

export const PublicRegistryTiles: React.FC<PublicRegistryTilesProps> = ({
  items = [],
  zolaGiftCard,
  onItemClick,
  openItemDetail,
  isZolaGiftCardEnabled,
}) => {
  const list: ListItem[] = useMemo(() => {
    const availableItemCount = items?.filter(isItemAvailable).length || 0;
    const newList: ListItem[] = [...items];
    if (zolaGiftCard) {
      newList.splice(availableItemCount, 0, { type: 'zolaGiftCard' });
    }
    return newList;
  }, [items, zolaGiftCard]);

  // If the URL has a collection item or product look query param, open the item detail modal
  useEffect(() => {
    const {
      collectionItemId: collectionItemIdParam,
      splitOrderId: splitOrderIdParam,
      token: tokenParam,
      orderId: orderIdParam,
      reservedQuantity: reservedQuantityParam,
      productLookKey: productLookKeyParam,
    } = queryString.parse(window.location.search);
    let url = `${window.location.origin}${window.location.pathname}`;
    if (collectionItemIdParam) {
      if (splitOrderIdParam && tokenParam && reservedQuantityParam) {
        url +=
          `?collectionItemId=${collectionItemIdParam}` +
          `&splitOrderId=${splitOrderIdParam}` +
          `&token=${tokenParam}` +
          `&orderId=${orderIdParam || ''}` +
          `&reservedQuantity=${reservedQuantityParam}`;
      }
      window.history.replaceState({}, '', url);
      const item = list.find(
        (listItem) => 'object_id' in listItem && listItem.object_id === collectionItemIdParam
      );
      if (item) {
        openItemDetail(item as WRegistryCollectionItemView);
      }
    } else if (productLookKeyParam === 'zola_gift_card' && zolaGiftCard) {
      window.history.replaceState({}, '', url);
      onItemClick(zolaGiftCard);
    }
  }, [list, onItemClick, openItemDetail, zolaGiftCard]);

  return (
    <RegistryTilesContainer>
      {list.map((item) => {
        if (item.type === 'placeholder') return null;

        if (item.type === 'zolaGiftCard') {
          return (
            <PublicTileContainer key="zolaGiftCard">
              <RegistryZolaGiftCard
                hideMobileEdit
                onItemClick={onItemClick}
                zolaGiftCard={zolaGiftCard ?? null}
                isZolaGiftCardEnabled={Boolean(isZolaGiftCardEnabled)}
              />
            </PublicTileContainer>
          );
        }

        return (
          <PublicTileContainer key={item.item_id}>
            <PublicRegistryTile
              draggable={false}
              item={item}
              onItemClick={onItemClick}
              openItemDetail={openItemDetail}
            />
          </PublicTileContainer>
        );
      })}
    </RegistryTilesContainer>
  );
};
