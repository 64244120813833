import { Fragment, useEffect } from 'react';

import type { WProductView } from '@zola/svc-web-api-ts-client';
import { FooterV2 as Footer } from '@zola/zola-ui/src/components/FooterV2';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors';
import { COLORS_GRAYS } from '@zola/zola-ui/src/styles/emotion/colors3';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { receiveUser } from '@zola-helpers/client/dist/es/redux/user/userActions';

import { GetServerSideProps } from 'next';
import { StickyContainer } from 'react-sticky';

import { receiveRegistryCollection, updateRegistryKey } from '@/actions/ManageRegistryActions';
import { receivedProductByLookKey } from '@/actions/types/ProductActionTypes';
import { receivedRegistryBySlug } from '@/actions/types/RegistryActionTypes';
import type { UserContext } from '@/actions/UserActions.type';
import PublicRegistryNav from '@/components/common/header/PublicRegistryNav';
import ModalRoot from '@/components/common/ModalRoot';
import Notifications from '@/components/common/notifications/Notifications';
import ToastsContainer from '@/components/common/ToastsContainer';
import { RegistryFilters } from '@/components/registry/RegistryFilters';
import { getPublicRegistryNavHtml } from '@/lib/server/api/nav';
import { getProductByLookKey } from '@/lib/server/api/product';
import { getRegistryInfoBySlug, searchRegistryCollection } from '@/lib/server/api/registry';
import { getUserContext } from '@/lib/server/api/user';
import { RegistryHero } from '@/pages/ManageRegistry/RegistryHero/RegistryHero';
import {
  Container,
  FooterContainer,
  Header,
} from '@/pages/PublicRegistryNew/components/PublicRegistry.styles';
import { RegistryTiles } from '@/pages/PublicRegistryNew/components/RegistryTiles';
import SeoTags from '@/pages/PublicRegistryNew/components/SeoTags';
import { PublicRegistryProvider } from '@/pages/PublicRegistryNew/contexts/PublicRegistryProvider';
import { initializeStore } from '@/store/nextStore';
import type { RegistryType } from '@/types/registry';
import { trackPublicRegistryViewed } from '@/util/trackingHelper';

const {
  EVENTS: { PUBLIC_REGISTRY_EVENT },
} = NAV_DATA;

const PUBLIC_REGISTRY_NAV_EVENT = 'unified-nav-mounted';
const GIFT_CARD_LOOK_KEY = 'zola_gift_card';

const dispatchPublicRegistryEvent = () =>
  window.dispatchEvent(new CustomEvent(PUBLIC_REGISTRY_EVENT, { detail: 'ENTER_V2' }));

type PublicRegistryPageProps = {
  navHtml: string | null;
  userContext: UserContext | null;
  registryKey: string;
  registryOverview: RegistryType;
  isOwnRegistry: boolean;
  giftCardData: WProductView | null;
};

const PublicRegistryPage = (props: PublicRegistryPageProps): JSX.Element => {
  const { registryKey, userContext, navHtml, registryOverview, isOwnRegistry, giftCardData } =
    props;

  const { registry_object_id: registryObjectId } = registryOverview;

  useEffect(() => {
    /*
     * Handles body styling
     */
    document.body.style.backgroundColor = COLORS_GRAYS.BLACK_005;

    // fire event after unified nav is mounted
    window.addEventListener(PUBLIC_REGISTRY_NAV_EVENT, dispatchPublicRegistryEvent);

    return () => {
      window.removeEventListener(PUBLIC_REGISTRY_NAV_EVENT, dispatchPublicRegistryEvent);
      document.body.style.backgroundColor = COLORS.ALABASTER;
    };
  }, []);

  useEffect(() => {
    /*
     * Send tracking event for guest view only
     */
    if (!isOwnRegistry && registryObjectId) {
      trackPublicRegistryViewed(registryObjectId);
    }
  }, [registryObjectId, isOwnRegistry]);

  return (
    <Fragment>
      <PublicRegistryProvider
        userContext={userContext}
        registryKey={registryKey}
        registryOverview={registryOverview}
        isOwnRegistry={isOwnRegistry}
        giftCardData={giftCardData}
      >
        <SeoTags registry={registryOverview} />
        <Header>
          <StickyContainer>
            <PublicRegistryNav html={navHtml} />
          </StickyContainer>
        </Header>
        <Container data-testid="standalone-public-registry">
          <RegistryHero isSSR />
          <div>
            <RegistryFilters showSort />
          </div>
          <div>
            <RegistryTiles />
          </div>
        </Container>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </PublicRegistryProvider>
      <ModalRoot />
      <Notifications />
      <ToastsContainer />
    </Fragment>
  );
};

export const getServerSideProps: GetServerSideProps<PublicRegistryPageProps> = async (context) => {
  const reduxStore = initializeStore();
  const { dispatch, getState } = reduxStore;

  const { registryKey } = context.params || {};
  const { preview, admin_preview } = context.query || {};

  const [registryOverview, navHtml, userContext] = await Promise.all([
    getRegistryInfoBySlug(registryKey as string),
    getPublicRegistryNavHtml(context.req),
    getUserContext(context.req),
  ]);

  // registry does not exist
  if (!registryOverview) {
    return {
      notFound: true,
    };
  }

  if (userContext) {
    dispatch(receiveUser(userContext));
  }

  dispatch(receivedRegistryBySlug(registryOverview));

  const {
    public: isPublicRegistry,
    registry_object_id: registryObjectId,
    default_gift_card_enabled: isZolaGiftCardEnabled,
  } = registryOverview as unknown as any; // typescript is complaining because the type has _public
  const { registry_ids: registryIds } = userContext || {};
  const isOwnRegistry = (!!registryObjectId && registryIds?.includes(registryObjectId)) || false;
  const canViewRegistry = isPublicRegistry || isOwnRegistry;
  const isCouplePreview = preview === 'true';
  const isAdminPreview = admin_preview === 'true';

  /*
   * When the post auth user is accessing their own
   * registry, outside the admin preview feature,
   * redirect to the registry edit page
   */
  if (isOwnRegistry && !isCouplePreview) {
    return {
      redirect: {
        destination: `/registry/${registryKey}/edit`,
        permanent: false,
      },
    };
  }

  /*
   * When the registry is:
   * - Not public or not the post auth user registry
   * - And accessed outside the admin preview context
   * redirect to not found page
   */
  if (!canViewRegistry && !isAdminPreview) {
    return {
      notFound: true,
    };
  }

  const [registryCollectionItems, giftCardData] = await Promise.all([
    searchRegistryCollection(registryKey as string),
    isZolaGiftCardEnabled ? getProductByLookKey(GIFT_CARD_LOOK_KEY) : Promise.resolve(null),
  ]);

  if (giftCardData) {
    dispatch(receivedProductByLookKey(giftCardData));
  }
  if (registryCollectionItems) {
    dispatch(receiveRegistryCollection(registryCollectionItems));
  }
  dispatch(updateRegistryKey(registryKey as string));

  const initialReduxState = JSON.stringify(getState());

  return {
    props: {
      navHtml,
      userContext,
      registryKey: registryKey as string,
      registryOverview,
      isOwnRegistry,
      giftCardData: giftCardData || null,
      initialReduxState,
    },
  };
};

export default PublicRegistryPage;
