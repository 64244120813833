import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import styled from '@emotion/styled';

import { TileContainer } from './RegistryTile.styles';

export const RegistryTilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-right: -1px;

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${MEDIA_QUERY.DESKTOP} {
    margin: 0 -16px;
    grid-template-columns: repeat(4, 1fr);
  }
  ${MEDIA_QUERY.DESKTOP_LG} {
    grid-template-columns: repeat(5, 1fr);
  }
  ${MEDIA_QUERY.DESKTOP_XL} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const PublicTileContainer = styled.div`
  display: flex;
  min-width: 0;

  ${TileContainer} {
    ${MEDIA_QUERY.DESKTOP} {
      padding: 16px;
    }
  }
`;
