import { PublicRegistryTiles } from '@/components/common/RegistryTiles/PublicRegistryTiles';
import { useRegistryActions } from '@/hooks/useRegistryActions';
import { getManageRegistryCollections } from '@/selectors/manageRegistrySelectors';
import { getDefaultGiftCard } from '@/selectors/productSelectors';
import { useAppSelector } from '@/store';

import { ManageRegistryTilesContainer } from '../../ManageRegistry/ManageRegistryTiles.styles';
import { usePublicRegistry } from '../contexts/PublicRegistryContext';

export const RegistryTiles = () => {
  const collections = useAppSelector(getManageRegistryCollections);
  const zolaGiftCard = useAppSelector(getDefaultGiftCard);
  const { registryKey, registryOverview, giftCardData } = usePublicRegistry();
  const { default_gift_card_enabled: isZolaGiftCardEnabled } = registryOverview || {};
  const { handleItemClick, handleProductClick } = useRegistryActions(registryKey);

  return (
    <ManageRegistryTilesContainer>
      <PublicRegistryTiles
        items={collections}
        zolaGiftCard={isZolaGiftCardEnabled ? zolaGiftCard || giftCardData : null}
        onItemClick={handleItemClick}
        openItemDetail={handleProductClick}
        isZolaGiftCardEnabled={Boolean(isZolaGiftCardEnabled)}
      />
    </ManageRegistryTilesContainer>
  );
};
